<template>
  <a-tooltip :title="full ? '取消全屏' : '全屏'">
    <div>
      <fullscreen-exit-outlined v-if="full" @click="handleClick" />
      <fullscreen-outlined v-else @click="handleClick" />
    </div>
  </a-tooltip>
</template>

<script>
import { Tooltip } from 'ant-design-vue';
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
} from '@ant-design/icons-vue';
import screenfull from 'screenfull';

export default {
  name: 'FullScreen',
  components: {
    ATooltip: Tooltip,
    FullscreenOutlined,
    FullscreenExitOutlined,
  },
  data() {
    return {
      full: false,
    };
  },
  mounted() {
    screenfull.isEnabled &&
      screenfull.onchange(() => {
        this.full = screenfull.isFullscreen;
      });
  },
  methods: {
    handleClick() {
      if (screenfull.isEnabled) {
        screenfull.toggle();
      } else {
        this.$message.warning('you browser can not work');
      }
    },
  },
};
</script>
