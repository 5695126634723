'use strict';

import { createStore } from 'vuex';

const context = require.context('./modules', false, /([a-z_]+)\.js$/i);

const modules = context
  .keys()
  .map(key => ({ key, name: key.match(/([a-z_]+)\.js$/i)[1] }))
  .reduce((ms, { key, name }) => ({ ...ms, [name]: context(key).default }), {});

export default createStore({ modules });
