'use strict';

export default {
  namespaced: true,
  state: {
    enums: null,
    apps: null,
    breadcrumbs: null,
    loading: true,
    questionnaire: {},
    // 初始化空数组
    questionnaireFields: [],
    bannerChart: {},
    activityId: null,
    id: null,
    status: null,
    saveId: null,
    CampaignOrgId: null,
    CreateUser: null,
    orgIds: [],
    userId: null,
    selectedItem: [],
  },
  mutations: {
    setEnums(state, value) {
      state.enums = value;
    },
    setApps(state, value) {
      state.apps = value;
    },
    setBreadcrumbs(state, value) {
      state.breadcrumbs = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setBannerChart(state, value) {
      state.bannerChart = value;
    },
    setQuestionnaire(state, value) {
      state.questionnaire = value;
    },
    setQuestionnaireFields(state, value) {
      state.questionnaireFields = value;
    },

    setSelectedItem(state, value) {
      state.selectedItem = value;
    },
    setActivityId(state, value) {
      state.activityId = value;
    },
    setId(state, value) {
      state.id = value;
    },
    setStatus(state, value) {
      state.status = value;
    },
    setSaveId(state, value) {
      state.saveId = value;
    },
    setCampaignOrgId(state, value) {
      state.CampaignOrgId = value;
    },
    setCreateUser(state, value) {
      state.CreateUser = value;
    },
    setOrgIds(state, value) {
      state.orgIds = value;
    },
    setUserId(state, value) {
      state.userId = value;
    }, 
  },
};
