'use strict';

export default {
  install: app => {
    app.directive('focus', {
      mounted(el) {
        el.tagName === 'INPUT' ? el.focus() : el.querySelector('input').focus();
      },
    });
  },
};
