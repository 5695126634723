'use strict';

import { createApp } from 'vue';
import directive from './directives'; // global directive
import plugin from './plugins'; // global plugin
import router from './router';
import store from './store';
import i18n from './i18n';
import App from './App.vue';
import './registerServiceWorker';
import './assets/less/main.less';
import './assets/css/ckeditor.css';

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(directive)
  .use(plugin)
  .mount('#main-container');
