<template>
  <a-config-provider :locale="locale" :getPopupContainer="popupContainer">
    <router-view />
  </a-config-provider>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ConfigProvider } from 'ant-design-vue';

//导入echarts
// import echarts from 'echarts';
// computed.prototype.$echarts = echarts;

export default {
  components: {
    AConfigProvider: ConfigProvider,
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    return {
      locale: computed(
        () => i18n.getLocaleMessage(store.state.account.language).antdLocale
      ),
      popupContainer: node => {
        let list = node?.getAttribute('class')?.split(' ')
        let select = null
        list?.find((item)=> {
          if (item === 'ant-select' || item === 'ant-calendar-picker-input' || item === 'ant-dropdown-trigger' || item === 'ant-dropdown-open') {
            return select = true
          }
        })

        if (node && (node.getAttribute('class') === 'ant-select-selector' || select )) {
          return node.parentNode;
        }
        return document.body;
      },
    };
  },
};
</script>
