'use strict';

import { createRouter, createWebHashHistory } from 'vue-router';
import NProgress from 'nprogress';
import Layout from '@/components/layout';
import store from '@/store';
import userApi from '@/api/user.js';
import personalApi from '@/api/personal';
import { redirect, others } from './config.json';
import 'nprogress/nprogress.css';

let addApps = [];
const whiteList = ['/login','/wechatTemplateH5', '/digitalHumans','/weixinDetail'];
const analysisRoutes = list =>
  list.map(({ children, component, accessWay, meta, ...others }) => {
    if (children) return analysisRoutes(children);
    if (accessWay === 'qiankun') {
      addApps.push({ ...others, entry: component });
      router.addRoute('main', {
        path: `${others.path}/:microAppRoute*`,
        name: `microApp-${others.name}`,
        component: () => import('@/views/microApp/qiankun'),
        meta: { ...meta, accessWay },
      });
    } else {
      router.addRoute('main', {
        meta: { ...meta, accessWay },
        ...others,
        component: () =>
          import(
            `@/views/${accessWay === 'iframe' ? 'microApp/iframe' : component}`
          ),
      });
    }
  });
const initData = async ({ userId }) => {
  let replace = false;
  const { account } = store.state;
  if (!account.menus) {
    const menus = await userApi.getMenus();
    store.commit('account/setMenus', menus);
    analysisRoutes(menus.concat(others));
    replace = true;
  }
  if (!account.apps) {
    const apps = await userApi.getApps(userId);
    store.commit('account/setApps', apps);
    apps.forEach(({ id, applicationKey, entryUrl, entry, iframe, status }) => {
      status &&
        iframe === false &&
        addApps.push({
          appId: id,
          name: applicationKey,
          entry: entryUrl,
          path: `/marketing/activity/:type/:id/:eventId/:campaignId/app${entry}`,
        });
    });
    store.commit('common/setApps', addApps);
  }
  account.permissions ||
    store.commit('account/setPermissions', await userApi.getPermissions());
  return replace;
};

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login'),
      meta: { title: '登录' },
    },
    {
      path: '/wechatTemplateH5',
      name: 'wechatTemplateH5',
      component: () => import('@/views/wechatTemplateH5'),
      meta: { title: '微信消息模版预览' },
    },
    {
      path: '/digitalHumans',
      name: 'digitalHumans',
      component: () => import('@/views/digitalHumans'),
      meta: { title: '数字人介绍' },
    },
    {
      path: '/weixinDetail',
      name: 'weixinDetail',
      component: () => import('@/views/weixinDetail'),
      meta: { title: '' },
    },
    {
      path: '/404',
      //component: () => import('@/views/error/404'),
      meta: { title: '404' },
    },
    {
      path: '/',
      name: 'main',
      component: Layout,
      redirect,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
    },
  ],
});

router.beforeEach(async ({ name, fullPath, path, meta, params }) => {
  NProgress.start();

  if (['cluescoreDetail', 'groupEdit', 'tagmanageEdit'].includes(name)) {
    meta.title =
      params.status === 'add'
        ? '添加'
        : params.status === 'watch'
        ? '查看'
        : '编辑';
  }
  if (path === '/activityCenter/activity') {
    store.commit('common/setStatus', false);
    store.commit('common/setSaveId', false);
    store.commit('common/setSelectedItem', []);
  }
  // if (name === 'tagmanageEdit') {
  //   meta.title =
  //     params.status === 'add'
  //       ? '新增'
  //       : params.status === 'watch'
  //       ? '查看'
  //       : '编辑';
  // }
  name.startsWith('microApp-') ||
    (meta?.title &&
      (document.title = `${meta.title} - ${process.env.VUE_APP_PROJECT_TITLE}`));
  if (whiteList.includes(path)) return true;
  let { token, user } = store.state.account;
  if (token) {
    if (!user) {
      user = await personalApi.getOwn();
      store.commit('account/setUser', user);
    }
    return (await initData(user)) ? fullPath : true;
  } else {
    if(path === '/wechatTemplateH5'){
      return true
    }
    if(path === '/digitalHumans'){
      return true
    }
    if(path === '/weixinDetail'){
      return true
    }

    return '/login';
  }
});

router.afterEach(() => NProgress.done());

export default router;
