'use strict';

import http from '@/utils/http';

export default {
  getItemById(code, id) {
    return http.get(`/authority/user/${id}`, { code });
  },
  getOwn() {
    return http.get('/authority/anno/userInfo');
  },
  update(code, data) {
    return http.put('/authority/user', data, { code });
  },
  updatePassword(data, code) {
    return http.put('/authority/user/password', data, { code });
  },
};
