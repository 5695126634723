'use strict';

import { createI18n } from 'vue-i18n';
import moment from 'moment';
import store from '@/store';
import zhCN from './lang/zh-CN/index';

export const defaultLang = 'zh-CN';

const i18n = createI18n({
  locale: defaultLang,
  legacy: false,
  globalInjection: true,
  fallbackLocale: defaultLang,
  messages: { 'zh-CN': zhCN },
});
const loadedLanguages = [defaultLang];
const setI18nLanguage = lang => {
  document.querySelector('html').setAttribute('lang', lang);
  i18n.global.locale = lang;
  return lang;
};

export function loadLanguageAsync(lang = defaultLang) {
  return new Promise(resolve => {
    // 缓存语言设置
    store.commit('account/setLanguage', lang);
    if (i18n.locale === lang) return resolve(lang);
    if (loadedLanguages.includes(lang)) {
      return resolve(setI18nLanguage(lang));
    }
    return import(`./lang/${lang}/index`).then(msg => {
      const locale = msg.default;
      i18n.global.setLocaleMessage(lang, locale);
      loadedLanguages.push(lang);
      moment.updateLocale(locale.momentName, locale.momentLocale);
      return setI18nLanguage(lang);
    });
  });
}

loadLanguageAsync(store.state.account.language);

export default i18n;
