<template>
  <a-dropdown v-bind="$props">
    <div class="iss-user">
      <a-avatar :src="setAvatar(user.avatar)" :size="30" />
      <span class="user-name">{{ user.name }}</span>
      <caret-down-outlined />
    </div>
    <template #overlay>
      <a-menu @click="handleClick">
        <a-menu-item key="personal">
          <smile-outlined :style="{ fontSize: '15px' }" />
          <span>个人中心</span>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item key="logout">
          <logout-outlined :style="{ fontSize: '15px' }" />
          <span>退出</span>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { Avatar, Dropdown, Menu } from 'ant-design-vue';
import {
  CaretDownOutlined,
  LogoutOutlined,
  SmileOutlined,
} from '@ant-design/icons-vue';
import { setAvatar } from '@/utils';
import { local, session } from '@/utils/storage';
import loginApi from '@/api/login';

export default {
  name: 'User',
  components: {
    AAvatar: Avatar,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AMenuDivider: Menu.Divider,
    CaretDownOutlined,
    SmileOutlined,
    LogoutOutlined,
  },
  computed: {
    user() {
      return this.$store.state.account.user;
    },
  },
  methods: {
    setAvatar,
    handleClick({ key }) {
      switch (key) {
        case 'personal':
          this.$router.push({ name: 'personalCenter' });
          break;
        case 'logout':
          loginApi.logout('').then(() => {
            local.clear();
            session.clear();
            location.reload();
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.iss-user {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #313d5f;
  .user-name {
    margin: 0 5px;
  }
}
</style>
